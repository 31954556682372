import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {Formik, Form} from 'formik';
import Loader from 'react-loader-spinner';

import {submit} from '../evag';

import Button from './Button';
import ModalError from './ModalError';
import ShareButtons from './ShareButtons';

import InputCheckbox from './form/InputCheckbox';
import InputLocation from './form/InputLocation';
import InputText from './form/InputText';

import styles from './FormSignature.module.scss';
import CountSignatures from './CountSignatures';

const initialValues = {
  name: '',
  email: '',
  phone: '',
  uf: '',
  city: '',
  school: '',
  entity: '',
  subscribe: false,
};

const validationSchema = Yup.object().shape({
  name: Yup
    .string()
    .required('O campo Nome é obrigatório.'),
  email: Yup
    .string()
    .email('Digite um e-mail válido.')
    .required('O campo E-mail é obrigatório.'),
  phone: Yup
    .string(),
  uf: Yup
    .string()
    .required('O campo Estado é obrigatório.'),
  city: Yup
    .string()
    .required('O campo Cidade é obrigatório.'),
  school: Yup
    .string(),
  entity: Yup
    .string(),
  subscribe: Yup
    .bool(),
});

class FormSignature extends React.Component {
  constructor(props) {
    super(props);

    this.dismissError = this.dismissError.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.submit = this.submit.bind(this);

    this.state = {
      submitted: false,
    };
  }

  dismissError() {
    this.setState({
      error: null,
    });
  }

  submit(values, {setFieldError, setSubmitting}) {
    submit(this.props.channel, values)
      .then(() => {
        this.setState({
          submitted: true,
        });
      })
      .catch(error => {
        if (error.response && typeof error.response.data === 'object') {
          for (let name in error.response.data) {
            if (!error.response.data.hasOwnProperty(name)) {
              continue;
            }
            setFieldError(name, error.response.data[name]);
          }
          return;
        } else if (error.response) {
          // Server returned error.
          console.error(error.response);
        } else if (error.request) {
          // No response received.
          console.error(error.request);
        } else {
          // Something triggered an error when setting the request.
          console.error(error.message);
        }

        this.setState({
          error: 'Ocorreu um erro inesperado ao enviar o formulário. Tente novamente mais tarde.',
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  }

  renderForm() {
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.submit}
        validationSchema={validationSchema}
      >
        {({values, handleBlur, handleChange, isSubmitting, setFieldValue}) => (
          isSubmitting
            ? (
              <div style={{margin: '20px auto', width: '100px'}}>
                <Loader
                  type="Ball-Triangle"
                  color="#000000"
                  height="100"
                  width="100"
                />
              </div>
            )
            : (
              <Form action={`https://api.evag.io/channels/${this.props.channel}/submit`} method="post">
                <InputText
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Nome completo"
                  value={values.name}
                />
                <InputText
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="E-mail"
                  type="email"
                  value={values.email}
                />
                <InputText
                  cleave={{blocks: [0, 2, 9], delimiters: ['(', ') '], numericOnly: true}}
                  name="phone"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="WhatsApp"
                  type="tel"
                  value={values.phone}
                />
                <InputLocation
                  names={['uf', 'city']}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  values={values}
                />
                <InputText
                  name="school"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Universidade"
                  value={values.school}
                />
                <InputText
                  name="entity"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Entidade/Movimento"
                  value={values.entity}
                />

                <InputCheckbox
                  className={styles.checkbox}
                  label="Quero receber novidades por e-mail"
                  name="subscribe"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.subscribe}
                />

                <Button type="submit" fontSize="16px" width="100%">Enviar</Button>
                <ModalError
                  error={this.state.error}
                  dismiss={this.dismissError} />
              </Form>
            )
        )}
      </Formik>
    );
  }

  render() {
    return (
      <section className={styles.container} id="sign">
        <div className="wrap">
          <h3 className={styles.title}>Preencha o formulário para assinar o manifesto.</h3>

          {this.state.submitted
            ? (
              <React.Fragment>
                <p><strong>Obrigada por assinar!</strong> Agora ajude a espalhar compartilhando nas suas redes sociais.</p>

                <blockquote className={styles.blockquote}>
                  <p>Assine o Manifesto em Defesa da Amazônia, um chamado dos estudantes nortistas para uma luta que é de todo o Brasil.</p>
                  <p>https://amazonia.juntos.org.br/</p>
                </blockquote>

                <div className={styles.share}>
                  <ShareButtons title="Assine o Manifesto em Defesa da Amazônia, um chamado dos estudantes nortistas para uma luta que é de todo o Brasil." url="https://amazonia.juntos.org.br/" />
                </div>
              </React.Fragment>
            )
            : (
              <React.Fragment>
                <CountSignatures bar={false} channel={this.props.channel} />
                {this.renderForm()}
              </React.Fragment>
            )}
        </div>
      </section>
    );
  }
}

FormSignature.propTypes = {
  channel: PropTypes.string.isRequired,
};

export default FormSignature;
