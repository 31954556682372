import React from 'react';
import PropTypes from 'prop-types';
import {ErrorMessage} from 'formik';
import Cleave from 'cleave.js/react';

import styles from './form.module.scss';

class InputText extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  focus() {
    this.ref.focus();
  }

  render() {
    const {className, cleave, type, ...props} = this.props;
    const cls = styles.text + (className ? ` ${className}` : '');

    const ref = r => {
      this.ref = r;
    };

    let element;
    if (type === 'text') {
      element = <input className={cls} ref={ref} type={type} {...props} />;
    } else {
      element = <Cleave className={cls} options={cleave} htmlRef={ref} type={type} {...props} />;
    }

    return (
      <div style={{position: 'relative'}}>
        {element}
        <ErrorMessage name={props.name} component="div" className={styles.error} />
      </div>
    );
  }
}

InputText.defaultProps = {
  className: '',
  cleave: {},
  type: 'text',
};

InputText.propTypes = {
  className: PropTypes.string,
  cleave: PropTypes.object,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
};

export default InputText;
