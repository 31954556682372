import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';

import FormSignature from '../components/FormSignature';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import SEO from '../components/SEO';

export default function render({data}) {
  return (
    <Layout>
      <SEO title={data.site.siteMetadata.title} />

      <Hero
        channel={data.site.siteMetadata.channel}
      />

      <main className="main">
        <div className="wrap">
          <blockquote>
            <p>“A floresta está viva. Só vai morrer se os brancos insistirem em destruí-la. Se conseguirem, os rios vão desaparecer debaixo da terra, o chão vai se desfazer, as árvores vão murchar e as pedras vão rachar no calor. A terra ressecada ficará vazia e silenciosa.”</p>

            <footer><cite>(A Queda do Céu - Palavras de um xamã yanomami, Davi Kopenawa)</cite></footer>
          </blockquote>

          <p>Este manifesto se inicia com as palavras de Davi Kopenawa, para alertar não só o Movimento Estudantil, mas toda comunidade civil sobre os grandes impactos ambientais que vem causando a destruição em massa do meio ambiente.</p>

          <p>A Amazônia sempre foi objeto de cobiça por parte do capitalismo predatório. Desde a colonização, até os dias de hoje, nossas florestas são vistas como território de exploração. É histórico a narrativa da necessidade de grandes projetos na Amazônia tendo como exemplos atuais a construção de Belo Monte e a expansão de mineradoras. O maior bioma natural do mundo, concentrado no Brasil, está tendo sua existência ameaçada pela lógica do lucro e renda.</p>

          <p>Um dos causadores desse impacto ambiental são as grandes queimadas na floresta amazônica, geradas pelo desmatamento desenfreado do agronegócio. Só no ano de 2019 registrou-se um aumento de 80% nos focos de incêndio na Amazônia brasileira, maior índice desde 2013, o Pará foi o estado com mais focos de queimadas em 2019, segundo o Inpe.</p>

          <p>A escolha de Bolsonaro por Ricardo Salles ao cargo de Ministro do Meio Ambiente não foi por acaso. Seus primeiros passos como ministro foram os gigantescos cortes nos órgãos de proteção da Amazônia, como o ICMBio e Ibama, gerando impactos em suas operações de fiscalização. Além disso, este ministro que já foi condenado em São Paulo por falsificar documentos em benefício de mineradoras, segue sendo cúmplice do maior crime ambiental e social do mundo em Brumadinho/MG com mais de 300 mortos, ajudando a garantir a impunidade à Vale.</p>

          <p>Bolsonaro é um grande aliado dos setores mais bárbaros do capitalismo, e já disse, com todas as palavras, que não se importa com o meio ambiente.</p>

          <h3>Alertas sobre os riscos ambientais</h3>

          <p>A grande fumaça que escureceu o céu do estado de São Paulo no dia 16 de Agosto, foi um alerta dos efeitos da destruição da Amazônia. A Amazônia queima ininterruptamente em Rondônia desde o dia 1 de agosto. O Acre e o Amazonas já decretaram estado de alerta. O Mato Grosso do Sul já assumiu não ter estrutura para controlar os incêndios.</p>

          <p>Bolsonaro quis culpar as ONGs pelas queimadas. Na verdade, fazendeiros no sul do Pará organizaram um final de semana de queimadas intensas, incentivados por um presidente que nega o aquecimento global e todo o acúmulo da ciência no Brasil e no mundo.</p>

          <h3>A luta em defesa da Amazônia é urgente e de todos</h3>

          <p>O Brasil sob o governo Bolsonaro passa por um momento caótico de ameaças, cortes e retiradas de direitos. De um lado, a educação sendo constantemente ameaçada com cortes de mais de R$ 6 bilhões. De outro a reforma da previdência, que retira a perspectiva de aposentadoria aos trabalhadores brasileiros. Daqui, vemos a nossa “Matria Verde” ser consumida pelo fogo.</p>

          <p>São vidas que estão sendo atacadas nessa devastação das florestas e rios amazônicos, como a dos povos indígenas, quilombolas e ribeirinhos, que estão em constante batalha pela preservação do meio ambiente e demarcação de suas terras que são territórios de sobrevivência desses povos e da natureza.</p>

          <p>Os estudantes de vários países do mundo já deram o recado: não seremos a geração que vai permitir que o capitalismo acabe com o nosso planeta. Por isso convocamos, em todo o Brasil, a Greve Global pelo Clima, no dia 20 de Setembro. Mais do que nunca, a Amazônia precisa dos estudantes brasileiros, e de todo o mundo!</p>

          <p>Quer organizar a luta na sua cidade, universidade ou escola? Entre em contato com a gente. Cole cartazes, tire fotos, grave vídeos. Vamos inundar as redes e as ruas em defesa da Amazônia.</p>

          <blockquote>
            <p>“No começo pensei que estivesse lutando para salvar seringueiras.<br />
            Depois imaginei que lutava pela Floresta Amazônica.<br />
            Agora, percebo que estou lutando pela humanidade.”</p>
            <footer><cite>(Chico Mendes)</cite></footer>
          </blockquote>

          <p><strong>Assinam este manifesto:</strong></p>

          <p>União dos Estudantes de Ensino Superior de Santarém – UES<br />
          Diretório Central dos Estudantes – DCE UFOPA<br />
          Diretório Acadêmico Indígena – DAIN UFOPA<br />
          Centro Acadêmico de História Clio – CACLIO UFOPA <br />
          Centro Acadêmico de História – CAHIS UFPA Belém<br />
          Centro Acadêmico de História – CAHIST UFPA Bragança<br />
          Centro Acadêmico de Ciências Sociais – CACS UFAC<br />
          Centro Acadêmico de História – CAHIS UFRRJ-IM<br />
          Federação do Movimento Estudantil de História – FEMEH Nacional<br />
          Grêmio Estudantil do Colégio Álvaro Adolfo da Silveira – Santarém<br />
          Centro Acadêmico de Comunicação – CACO UFPA<br />
          Centro Acadêmico de Terapia Ocupacional – CATO UFPA<br />
          Centro Acadêmico de Farmácia – CALF UFPA<br />
          Centro Acadêmico de Odontologia – Calo UFPA Belém<br />
          Centro Acadêmico de História – Belém<br />
          Centro Acadêmico de História – Bragança<br />
          Centro Acadêmico de Ciências Sociais Hecilda Veiga – Belém<br />
          Centro Acadêmico de Geografia – Belém<br />
          Centro Acadêmico de Filosofia – Belém</p>
        </div>
      </main>

      <FormSignature channel={data.site.siteMetadata.channel} />
    </Layout>
  );
}

render.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        channel
      }
    }
  }
`;
